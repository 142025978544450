// External
import styled, { css } from "styled-components";
import { safeWindow } from "../../utils/helpers/ssr";
import { Element } from "react-scroll";

export const Section = styled(Element)`
  padding: 70px 0;
  background-color: ${props => (props.bgColor ? props.bgColor : "#ffffff")};

  ${props =>
    props.fullscreen &&
    css`
      padding-top: 0;
      position: relative;
      height: calc(
        ${safeWindow.innerHeight ? `${safeWindow.innerHeight}px` : "95vh"} -
          80px
      );
    `}
`;
