import React from "react";

// External
import styled from "styled-components";
import { rgba } from "polished";
import { StaticImage } from "gatsby-plugin-image";
import { BsChevronDown } from "react-icons/bs";
import { scroller } from "react-scroll";

// Components
import Button from "../../components/form/Button";
import IconButton from "../../components/form/IconButton";
import SplashText from "../../components/SplashText";
import { Section } from "../../components/layout/layout-styles";
import { Heading as HeadingStyles } from "../../components/common/Typography/type-styles";

// Hooks
import useMediaQuery from "../../hooks/use-media-query";

// Global Variables
import { breakpoints, colors, fontFamily, spacing } from "../../../theme";
import { name } from "../../../config";

const ImageWrapper = styled.div`
  text-align: center;
  padding: ${spacing(10)} 0 ${spacing(5)};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    padding: ${spacing(3)} 0 ${spacing(5)};
  }
`;

const Heading = styled(HeadingStyles)`
  margin: ${spacing(6)} auto ${spacing(2)};
  @media only screen and (min-width: 420px) {
    font-size: 36px;
  }

  @media only screen and (min-width: 576px) {
    font-size: 44px;
    width: 80%;
  }

  @media only screen and (min-width: ${breakpoints.tablet}) {
    font-size: 52px;
  }

  @media only screen and (min-width: ${breakpoints.desktop}) {
    font-size: 60px;
  }

  @media only screen and (min-width: 1200px) {
    width: 70%;
  }

  @media only screen and (min-width: 1400px) {
    font-size: 66px;
  }

  @media only screen and (min-width: 1510px) {
    font-size: 72px;
    width: 60%;
  }
  @media only screen and (min-width: 1920px) {
    width: 55%;
  }

  @media only screen and (min-width: 2100px) {
    width: 45%;
  }

  @media only screen and (min-width: 2500px) {
    width: 35%;
  }
`;

const HelperText = styled.p`
  font-family: ${fontFamily.medium};
  font-size: 14px;
  width: 60%;
  margin: 0 auto;
  line-height: 140%;
  letter-spacing: 1%;
  text-align: center;

  @media only screen and (min-width: 420px) {
    font-size: 16px;
    width: 80%;
  }

  @media only screen and (min-width: 576px) {
    font-size: 18px;
  }
  @media only screen and (min-width: ${breakpoints.tablet}) {
    font-size: 20px;
    width: 60%;
  }
  @media only screen and (min-width: ${breakpoints.desktop}) {
    font-size: 24px;
    width: 55%;
  }

  @media only screen and (min-width: 1370px) {
    width: 50%;
  }

  @media only screen and (min-width: 1510px) {
    width: 40%;
  }

  @media only screen and (min-width: 1800px) {
    width: 30%;
  }
`;

const ContactMeButton = styled(Button)`
  margin: ${spacing(5)} auto;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    font-size: 24px;
    padding: ${spacing(5.25)} ${spacing(9.75)};
    margin: ${spacing(9.5)} auto;
  }
`;

const ScrollDownButton = styled(IconButton)`
  position: absolute;
  display: none;
  left: 50%;
  transform: translateX(-50%);
  bottom: ${spacing(5)};

  background-color: ${rgba(colors.black, 0)};

  &:hover,
  &:focus {
    background-color: ${rgba(colors.black, 0.05)};
  }

  &:active {
    background-color: ${rgba(colors.black, 0.1)};
  }
`;

const HeroSection = () => {
  // Hooks
  const isMobile = !useMediaQuery(`(min-width: ${breakpoints.tablet} )`);

  // Handler Functions
  const scrollToSkillsSection = () => {
    scroller.scrollTo("mySkills", {
      duration: 700,
      smooth: true,
      offset: -80,
    });
    // refs.mySkills.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToContactMeSection = () => {
    scroller.scrollTo("contactMe", {
      duration: 1000,
      smooth: true,
      offset: -80,
    });
    // refs.contactMe.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Section fullscreen name="hero" as="section">
      <ImageWrapper>
        {isMobile ? (
          <StaticImage
            src="../../images/me.jpeg"
            alt={name}
            width={208}
            height={208}
            imgStyle={{
              borderRadius: "50%",
            }}
            placeholder="none"
          />
        ) : (
          <StaticImage
            src="../../images/me.jpeg"
            alt={name}
            width={318}
            height={318}
            imgStyle={{
              borderRadius: "50%",
            }}
            placeholder="none"
          />
        )}
      </ImageWrapper>
      <Heading>
        Hello 👋🏻 I'm a Freelance &nbsp;
        <br />
        <SplashText variant="peach">Frontend</SplashText>
        &nbsp;Developer
      </Heading>
      <HelperText>
        My name is {name}. I craft scalable products with modern web
        technologies.
      </HelperText>
      <ContactMeButton onClick={scrollToContactMeSection}>
        Contact Me
      </ContactMeButton>
      <ScrollDownButton
        iconColor={colors.black}
        onClick={scrollToSkillsSection}
        ariaLabel="scroll to skills section"
      >
        <BsChevronDown />
      </ScrollDownButton>
    </Section>
  );
};

export default HeroSection;
