import React from "react";

// External
import { graphql } from "gatsby";

// Sections
import Layout from "../components/layout";
import HeroSection from "../containers/hero-section";
import Seo from "../components/SEO";

import loadable from "@loadable/component";

const ProjectsSection = loadable(() =>
  import("../containers/projects-section")
);
const ReviewsSection = loadable(() => import("../containers/reviews-section"));
const SkillsSection = loadable(() => import("../containers/skills-section"));
const BlogSection = loadable(() => import("../containers/blog-section"));

const ContactMeSection = loadable(() =>
  import("../containers/contact-me-section")
);
const AroundTheWebSection = loadable(() =>
  import("../containers/around-the-web-section")
);

// Query for fetching maximum 4 blog posts in a sorted manner
export const pageQuery = graphql`
  query FETCH_BLOG_POSTS {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      limit: 4
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      nodes {
        id
        frontmatter {
          slug
          title
          description
          series
          cover_src
          date(formatString: "Do MMMM, YYYY")
          read_time
        }
      }
      totalCount
    }
  }
`;

const IndexPage = ({
  data: {
    allMdx: { nodes: blogPosts },
  },
}) => {
  // check for scroll position on every scroll and update state
  return (
    <Layout>
      <Seo />
      <HeroSection />
      <ProjectsSection />
      <ReviewsSection />
      <SkillsSection />
      <BlogSection blogPosts={blogPosts} />
      <ContactMeSection />
      <AroundTheWebSection />
    </Layout>
  );
};

export default IndexPage;
