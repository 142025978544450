import * as React from "react";
// import splashImage from "../../images/brush-stroke.svg";
import splashImageGreenCyan from "../../images/brush-stroke-green-cyan.svg";
import splashImageGreen from "../../images/brush-stroke-green.svg";
import splashImagePeach from "../../images/brush-stroke-peach.svg";
import splashImagePinkRed from "../../images/brush-stroke-pink-red.svg";
import splashImagePink from "../../images/brush-stroke-pink.svg";
import splashImagePurple from "../../images/brush-stroke-purple.svg";
import PropTypes from "prop-types";

import styled from "styled-components";

const findImageForVariant = variant => {
  if (variant === "green-cyan") {
    return splashImageGreenCyan;
  }

  if (variant === "green") {
    return splashImageGreen;
  }

  if (variant === "peach") {
    return splashImagePeach;
  }

  if (variant === "pink-red") {
    return splashImagePinkRed;
  }

  if (variant === "pink") {
    return splashImagePink;
  }

  if (variant === "purple") {
    return splashImagePurple;
  }
};

// props: children
const SplashTextStyles = styled.span`
  display: inline-block;
  background-image: url(${props => findImageForVariant(props.variant)});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
`;

const SplashText = ({ variant, children }) => (
  <SplashTextStyles variant={variant}>{children}</SplashTextStyles>
);

// variant: peach | green | blue
SplashText.propTypes = {
  variant: PropTypes.oneOf([
    "green-cyan",
    "green",
    "peach",
    "pink-red",
    "pink",
    "purple",
  ]),
};

export default SplashText;
