// External
import styled from "styled-components";

// Internal
import { breakpoints, fontFamily } from "../../../../theme";

export const Heading = styled.h1`
  font-family: ${fontFamily.extraBold};
  font-size: 26px;
  line-height: 120%;
  letter-spacing: -1%;
  text-align: center;
  @media only screen and (min-width: ${breakpoints.tablet}) {
    font-size: 52px;
  }
  @media only screen and (min-width: ${breakpoints.desktop}) {
    font-size: 60px;
  }
`;
